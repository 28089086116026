<template>
  <div class="page-home">
    <background-video :video="video"></background-video>

    <article class="page-cont pos-r">
      <div class="aside pos-r" :class="{ active: active == true }">
        <div class="title" v-html="product.name"></div>
        <div>
          <ul class="sub-nav">
            <li class="flex">
              <i class="inline-b ico-1"></i>
              <span class="mt-6 ml-14">
                <router-link
                  class="subm-tc"
                  :to="{
                    path: '/article',
                    query: {
                      id: this.$route.query.id,
                      lang: lang(),
                      ch: 'itr',
                    },
                  }"
                  >{{ $t("product.chanPinJieShao") }}</router-link
                >
              </span>
            </li>
            <li class="flex">
              <i class="inline-b ico-2"></i>
              <span class="mt-6 ml-14">
                <router-link
                  class="subm-tc"
                  :to="{
                    path: '/article',
                    query: {
                      id: this.$route.query.id,
                      lang: lang(),
                      ch: 'prm',
                    },
                  }"
                  >{{ $t("product.canShuXinXi") }}</router-link
                ></span
              >
            </li>
            <li v-if="list.length > 0">
              <div class="flex" @click="down">
                <i class="inline-b ico-3"></i>
                <span class="mt-6 ml-14">{{ $t("product.ziLiaoXiaZai") }}</span>
                <i class="ico-down"></i>
              </div>
              <div class="sub-nav-item mt-5" v-if="list.length > 1">
                <div
                  class="nav-item"
                  v-for="(item, index) of list"
                  :key="index"
                  :class="{ active: index == cur }"
                  @click="tabchange(item.id, index)"
                >
                  {{ item.model }}
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="aside-nav pos-a" @click="onoff"></div>
      </div>

      <div class="btn-back" @click="$router.go(-1)">
        <span class="ico-back"></span>
        {{ $t("product.fanHui") }}
      </div>
    </article>
  </div>
</template>

<script>
import BackgroundVideo from "@/components/BackgroundVideo";
import { detail, down } from "../api/index.js";
import { getToken } from "@/utils/auth";
import langMinix from "../common/lang";
import { lang } from "@/utils/utils";
export default {
  name: "Product",
  components: { BackgroundVideo },
  data: () => {
    return {
      product: {},
      list: [],
      cur: 0,
      lang: lang,
      video: "",
      active: false,
      subtab: false,
    };
  },
  created() {},
  mounted() {
    this.loadProduct();
  },
  minixs: [langMinix],
  methods: {
    tabchange(id, index) {
      if (getToken()) {
        this.cur = index;
        try {
          down(id).then((res) => {
            this.toDown(res);
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$router.push(`/login?lang=${this.lang()}&back=true`);
      }
    },
    down() {
      if (getToken()) {
        try {
          down(this.list[0].id).then((res) => {
            this.toDown(res);
            return false;
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$router.push(`/login?lang=${this.lang()}&back=true`);
      }
    },
    loadProduct() {
      const id = this.$route.query.id;
      detail(id)
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.product = res.data;
            this.list = res.data.model || [];
            this.video = res.data.video;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    onoff() {
      this.active = !this.active;
    },

    toDown(res) {
      if (res.code === 0 && res.data) {
        window.open(
          location.origin + `/down?pdf=${encodeURIComponent(res.data)}`,
          "_blank"
        );
        return;
      }
      if ([-99, -401].includes(res.code)) {
        this.$message({
          message: "登录状态失效，请重新登录",
          type: "warning",
        });
        this.$router.push(`/login?lang=${this.lang()}&back=true`);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page-cont {
  width: var(--w1600);
  margin-left: auto;
  margin-right: auto;
  height: 900px;
  overflow: hidden;
}

.aside {
  width: 458px;
  height: 900px;
  background: rgba(205, 1, 13, 0.8);
  padding-left: 65px;
  padding-top: 68px;
  animation: moveani1 0.2s linear forwards;

  .aside-nav {
    width: 33px;
    height: 51px;
    right: -33px;
    top: 360px;
    cursor: pointer;
    background: url("../assets/img/ico-arrow.png") no-repeat;
  }

  .sub-nav li {
    width: 306px;
    height: 64px;
    background: #ffffff;
    border-radius: 32px;
    font-size: 32px;
    font-family: ABB;
    font-weight: bold;
    color: #323232;
    margin-bottom: 44px;

    a.subm-tc {
      color: #323232;
    }

    i {
      margin-left: 29px;
      margin-top: 8px;
    }

    .ico-1 {
      display: inline-block;
      width: 45px;
      height: 45px;
      margin-top: 10px;
      background: url("../assets/img/ico-1g.png") no-repeat;
    }

    .ico-2 {
      width: 45px;
      height: 45px;
      margin-top: 10px;
      background: url("../assets/img/ico-2g.png") no-repeat;
    }

    .ico-3 {
      width: 45px;
      height: 45px;
      background: url("../assets/img/ico-3g.png") no-repeat;
    }

    .ico-down {
      width: 20px;
      height: 16px;
      position: relative;
      margin-top: 25px;
      background: url("../assets/img/ico-down.png") no-repeat;
    }
  }

  .sub-nav > li:hover {
    background: #797979;
    cursor: pointer;
    color: #fff;

    a.subm-tc {
      color: white;
    }

    .ico-1 {
      display: inline-block;
      width: 45px;
      height: 45px;
      background: url("../assets/img/ico-1.png") no-repeat;
    }

    .ico-2 {
      width: 45px;
      height: 45px;
      background: url("../assets/img/ico-2.png") no-repeat;
    }

    .ico-3 {
      width: 45px;
      height: 45px;
      background: url("../assets/img/ico-3.png") no-repeat;
    }
  }

  .sub-nav li:hover .nav-item {
    display: block;
  }

  .nav-item {
    cursor: pointer;
    width: 306px;
    height: 58px;
    line-height: 58px;
    margin-top: 2px;
    background: #ffffff;
    border-radius: 29px;
    font-size: 28px;
    font-family: ABB;
    font-weight: 400;
    color: #000;
    text-align: center;
    border: 1px solid rgb(236, 233, 233);
    display: none;
    transition: all 0.5s linear;
  }

  .nav-item:hover {
    color: #ff000f;
  }
}

.aside.active {
  animation: moveani 0.2s linear forwards;

  .aside-nav {
    width: 33px;
    height: 51px;
    right: -33px;
    top: 360px;
    cursor: pointer;
    background: url("../assets/img/ico-arrow-a.png") no-repeat;
  }
}

@keyframes moveani {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-458px);
  }
}

@keyframes moveani1 {
  0% {
    transform: translateX(-458px);
  }
  100% {
    transform: translateX(0px);
  }
}

.title {
  height: 64px;
  line-height: 64px;
  font-size: 32px;
  font-family: ABB;
  font-weight: bold;
  color: #ffffff;
  padding-right: 10px;
  margin-bottom: 145px;
  position: relative;
}

.title::after {
  display: inline-block;
  width: 34px;
  height: 4px;
  background: #ffffff;
  content: "";
  position: absolute;
  top: -2px;
  left: 0;
}

.btn-back {
  cursor: pointer;
  width: 120px;
  height: 42px;
  background: #ff000f;
  border-radius: 21px;
  line-height: 42px;
  margin-left: 220px;
  font-size: 16px;
  text-align: center;
  font-family: ABB;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  right: 65px;
  bottom: 100px;
}

.ico-back {
  width: 20px;
  height: 20px;
  background: url("../assets/img/ico-back.png") no-repeat;
  background-size: 20px 20px;
  position: relative;
  top: 3px;
}
</style>
