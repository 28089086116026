var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-home"},[_c('background-video',{attrs:{"video":_vm.video}}),_c('article',{staticClass:"page-cont pos-r"},[_c('div',{staticClass:"aside pos-r",class:{ active: _vm.active == true }},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.product.name)}}),_c('div',[_c('ul',{staticClass:"sub-nav"},[_c('li',{staticClass:"flex"},[_c('i',{staticClass:"inline-b ico-1"}),_c('span',{staticClass:"mt-6 ml-14"},[_c('router-link',{staticClass:"subm-tc",attrs:{"to":{
                  path: '/article',
                  query: {
                    id: this.$route.query.id,
                    lang: _vm.lang(),
                    ch: 'itr',
                  },
                }}},[_vm._v(_vm._s(_vm.$t("product.chanPinJieShao")))])],1)]),_c('li',{staticClass:"flex"},[_c('i',{staticClass:"inline-b ico-2"}),_c('span',{staticClass:"mt-6 ml-14"},[_c('router-link',{staticClass:"subm-tc",attrs:{"to":{
                  path: '/article',
                  query: {
                    id: this.$route.query.id,
                    lang: _vm.lang(),
                    ch: 'prm',
                  },
                }}},[_vm._v(_vm._s(_vm.$t("product.canShuXinXi")))])],1)]),(_vm.list.length > 0)?_c('li',[_c('div',{staticClass:"flex",on:{"click":_vm.down}},[_c('i',{staticClass:"inline-b ico-3"}),_c('span',{staticClass:"mt-6 ml-14"},[_vm._v(_vm._s(_vm.$t("product.ziLiaoXiaZai")))]),_c('i',{staticClass:"ico-down"})]),(_vm.list.length > 1)?_c('div',{staticClass:"sub-nav-item mt-5"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:{ active: index == _vm.cur },on:{"click":function($event){return _vm.tabchange(item.id, index)}}},[_vm._v(" "+_vm._s(item.model)+" ")])}),0):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"aside-nav pos-a",on:{"click":_vm.onoff}})]),_c('div',{staticClass:"btn-back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('span',{staticClass:"ico-back"}),_vm._v(" "+_vm._s(_vm.$t("product.fanHui"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }